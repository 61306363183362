.video-controls-container {
    position: fixed;
    bottom: 64px;
    right: 64px;
    z-index: 2;
    
    height: 120px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .video-controls-container {
        bottom: 24px;
        right: auto;
        flex-direction: row;
        width: 258px;
        height: auto;
    }
    
}

.video-controls-container button {
    width: 120px;
}

.pause_button {
    background-color: #111;
}

.leave_button {
    background-color: #F20000;
}