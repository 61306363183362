/* Early access form */
.EarlyAccessForm-window {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    z-index: 1;

    background-color: black;
    color: #ffffff;

    padding: 60px;
}

.EarlyAccessForm-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2px;
}

.EarlyAccessForm-form-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 200px;
}



.EarlyAccessForm-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 345px;
}

@media screen and (max-width: 600px) {
    .EarlyAccessForm-window {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
    }

    .EarlyAccessForm-header {
        margin-top: 0px;
    }

    .EarlyAccessForm-form-container {
        margin-top: auto;
    }

    .EarlyAccessForm-form {
        width: 100%;
    }
    
}

.EarlyAccessForm-form .Consent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    font-size: 12px;

    width: 100%;
    margin-top: 12px;
    margin-bottom: 32px;
}


.EarlyAccessForm-form button {
    width: 100%;
}

.EarlyAccessForm-form button:hover {
    background-color: #00FF00;
    color: black;
}

.EarlyAccessForm-form b {
    padding: 7px 0px;
    margin: 0;
}

/* Submitted */
.EarlyAccessForm-submitted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 420px;
}

.EarlyAccessForm-submitted-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 85dvh;
}

.EarlyAccessForm-submitted h3 {
    color: #00FF00;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
}


.EarlyAccessForm-submitted button {
    background-color: #00FF00;
    color: black;
    margin-top: 24px;
    margin-bottom: 48px;
}

.EarlyAccessForm-submitted button:hover {
    background-color: #0000FF;
    color: white;
}

@media screen and (max-width: 600px) {
    .EarlyAccessForm-submitted {
        width: 289px;
    }
}

.EarlyAccessForm-submitted nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 275px;
}

.EarlyAccessForm-submitted nav a {
    color: white;
    font-weight: 700;
    text-decoration: none;
}

.EarlyAccessForm-submitted ._Social {
    color: var(--Shades-of-grey-Medium, #A9A9A9);
    display: flex;
    gap: 8px;
}

.EarlyAccessForm-submitted ._Social:hover {
    filter: brightness(0) saturate(100%) invert(73%) sepia(98%) saturate(3998%) hue-rotate(77deg) brightness(114%) contrast(135%);
}