.tile-video {
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .tile-video {

  }
}

/* Resize remote participants when a screen is being shared */
.is-screenshare .tile-video {
  width: 240px;
  height: 135px;
}

/* Put screen share front and center in our grid when there is one active */
.tile-screenshare {
  grid-column: 1 / -1;
  grid-row: 1;
}
