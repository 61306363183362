* {
    box-sizing: border-box; /* This ensures padding doesn't add to the width */
}
.AboutUsScreen {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;

    background-color: black;
    color: #ffffff;

    padding: 60px;
    overflow-y: auto;

}
.AboutUsScreen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    width: 30%;
    margin-bottom: 30px;
    margin-top: 0px;

}
.ProfileCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 40px; /* Adjust the gap between the cards as needed */
    padding: 20px; /* Give some padding around the grid */
    margin: -20px;/* This is to offset the padding on the grid */
  }

@media screen and (max-width: 600px) {
    .AboutUsScreen {
        padding: 24px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .AboutUsScreen-content {
        width: 88%;
        margin-top: 80px;
        width: 345px;
        align-items: right;
        /* justify-content: center; */
    }

    .ProfileCards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0px;
        padding: 40px; /* Give some padding around the grid */

    }

}

