.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    max-width: 250px;
    background-color:#000;
    padding-bottom:14px;
  }
  
.profileImage {
width: 250px;
height: 250px;
border-radius: 50%;
object-fit: cover;
margin-bottom:12px;
}
  
.name {
color:#ffff;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px;
}
  
.description {
color:#ffff;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px;
}
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:-8px;
  }
  
  .Twitter,
  .Linkedin {
    text-decoration: none;
    background-color:#0000;
    color:#0000;
    margin: 0 10px;
    border: none;

  }
  
  .twitter:hover {
    color: #1DA1F2;
  }
  
  .linkedin:hover {
    color: #0077b5;
  }

._SocialHover {
  color: #A9A9A9;
  display: flex;
  gap: 4px;
}

._SocialHover:hover {
    filter: brightness(0%) saturate(100%) invert(94%) sepia(64%) saturate(5871%) hue-rotate(92deg) brightness(103%) contrast(107%);
}