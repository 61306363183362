.LandingScreen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 60px;

    width: 100%;
}


/* Video background */
.LandingScreen .VideoBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    object-fit: cover;
    z-index: -1;

    opacity: 0.2;
    background-color: black;
}

.VideoBackground::-webkit-media-controls {
    display:none !important;
}

.LandingScreen .BackgroundColor {
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    z-index: -2;
}

/* Main */

.LandingMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 722px;
    margin-top: 172px;
    margin-bottom: 20px;
}

.FooterLanding{
    display:flex;
    position:fixed;
    justify-content: left;
    margin-top: 300px;
    padding:0px;
    margin-left: -50%;
}

.LandingMain h1 {
    font-family: 'ABCRepro', monospace;
    color: #ffffff;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    line-height: 74px;
    font-weight: 800;
    margin-bottom: 0px;
}

.LandingMain p {
    color: #ffffff;
    text-align: center;
    width: 416px;
    margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
    .LandingMain {
        margin-top: 80px;
        width: 345px;
        
    }

    .LandingMain h1 {
        font-size: 48px;
        line-height: 58px;
    }

    .LandingMain p {
        width: 345px;
    }
    
}

@media screen and (max-width: 600px) {
    .LandingScreen {
        padding: 24px;
    }

}
.SantaHijack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 60px;
}

.SantaFace {
    width: 392px;
    height: 460px;
}

@media screen and (max-height: 740px) {
    .SantaHijack {

        margin-top: 100px;
    }
}
@media screen and (min-height: 741px) {
    .SantaHijack {

        margin-top: 180px;
    }
} 
