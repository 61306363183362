/******************* Fonts *******************/
@font-face {
    font-family: 'ABCRepro';
    src: url('../src/Fonts/ABCRepro-Regular.woff2') format('woff2'),
        /* Modern Browsers */
        url('../src/Fonts/ABCRepro-Regular.woff') format('woff'),
        /* Older Browsers */
        url('../src/Fonts/ABCRepro-Regular.otf') format('opentype');
    /* Oldest Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ABCRepro';
    src: url('../src/Fonts/ABCRepro-Bold.woff2') format('woff2'),
        url('../src/Fonts/ABCRepro-Bold.woff') format('woff'),
        url('../src/Fonts/ABCRepro-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'ABCRepro';
    src: url('../src/Fonts/ABCRepro-Black.woff2') format('woff2'),
        url('../src/Fonts/ABCRepro-Black.woff') format('woff'),
        url('../src/Fonts/ABCRepro-Black.otf') format('opentype');
    font-weight: 800;
    font-style: oblique;
}

@font-face {
    font-family: 'ABCReproMono';
    src: url('../src/Fonts/ABCReproMono-Regular.woff2') format('woff2'),
        /* Modern Browsers */
        url('../src/Fonts/ABCReproMono-Regular.woff') format('woff'),
        /* Older Browsers */
        url('../src/Fonts/ABCReproMono-Regular.otf') format('opentype');
    /* Oldest Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ABCReproMono';
    src: url('../src/Fonts/ABCReproMono-Bold.woff2') format('woff2'),
        url('../src/Fonts/ABCReproMono-Bold.woff') format('woff'),
        url('../src/Fonts/ABCReproMono-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

/******************* Base Styles *******************/

html {
    margin: 0;
}

* {
    font-family: 'ABCReproMono', monospace;
}

.FLEX_COLUMN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.HIDE_SCROLL::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.HIDE_SCROLL {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Link {
    text-decoration: none;
}

/******************* Button *******************/
.Button {
    border-radius: 100px;

    margin: 0px;

    font-size: 14px;
    font-weight: 700;
    color: white;
    text-align: center;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    transition: 0.3s ease;
}
.Button16px {
    border-radius: 100px;

    margin: 0px;

    font-size: 16px;
    font-weight: 700;
    color: white;
    text-align: center;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    transition: 0.3s ease;
}

.Centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Button Fill */

._Fill {
    border: none;
    background-color: #0000FF;
}

._Fill:hover {
    color: #000000;
    background-color: #ffffff;
    border-radius: 0px;
}

/* Button Hollow */

._Hollow {
    border: 2px solid var(--White, #FFF);
    background: var(--effect-15-white, rgba(255, 255, 255, 0));
}

._Hollow:hover {
    background: var(--effect-15-white, rgba(255, 255, 255, 0.15));
    border-radius: 0px;
}

/* Button Sizes */

._ButtonSize1 {
    height: 52px;
    padding: 0px 32px 1px 32px;
}

._ButtonSize2 {
    height: 44px;
    padding: 0px 24px 1px 24px;
}

._ButtonSize3 {
    height: 32px;
    padding: 0px 16px 1px 16px;
}
._ButtonSize4 {
    height: 52px;
    padding: 0px 64px 1px 64px;
}
._ButtonSize5 {
    height: 52px;
    padding: 0px 64px 1px 64px;
}

._ButtonSize6{
    height: 44px;
    padding: 0px 109.5px 1px 109.5px;
}
@media (max-width:320px) {

    ._ButtonSize6{
        height: 44px;
        padding: 0px 24px 1px 24px;
    }
}
/******************* Input field *******************/
/* Custom styles for the input */
.CustomInput {
    /* Remove default input styles */
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box;
    font-size: 16px;
    font-family: 'ABCReproMono', monospace;
    padding: 14px 16px;
    margin-top: 3px;
    width: 100%;
    border: 2px solid #fff;
    border-radius: 12px;
    font-family: 'ABCRepro', monospace;
    /* White border */
    background-color: transparent;
    /* Transparent background */
    color: #fff;
    /* Text color */
}

/******************* Checkbox *******************/
/* The checkmark container */
.container {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/*****(❁´◡`❁)******* LOGO *****(❁´◡`❁)*******/
.Logo {
    width: 88px;
    height: 52px;
    margin-top:-11px;
    transition: filter 0.3s;
}
.LogoArrow {
    width: 122px;
    height: 84px;
    transition: filter 0.3s;
    display: flex;
    margin-top:-25.5px;
}

.Logo:hover {
    cursor: pointer;
    filter: brightness(0%) saturate(100%) invert(88%) sepia(54%) saturate(2839%) hue-rotate(357deg) brightness(103%) contrast(103%);
}
.LogoArrow:hover {
    cursor: pointer;
    filter: brightness(0%) saturate(100%) invert(88%) sepia(54%) saturate(2839%) hue-rotate(357deg) brightness(103%) contrast(103%);
}

.Arrow {
    width: 24px;
    height: 24px;
}
._ArrowBack {
    margin-top: -8px;
    margin-right: -2px;
}

@media screen and (max-width: 670px) {

    .LogoArrow {
        margin-left: 0px;
        margin-top: -16.5px;
    }

    .Arrow {
        width: 18px;
        height: 18px;
    }
    ._ArrowBack {
        margin-top:4.75px;
        margin-right: -4px;
    }
    .Logo {

        transition: filter 0.0s;

    }
}
@media screen and (min-height: 800px) {
    .Arrow {
        width: 24px;
        height: 24px;
    }
    ._ArrowBack {
        margin-top:4.75px;
        margin-right: -4px;
    }
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 2px solid #fff;
    background: var(--effect-15-white, rgba(255, 255, 255, 0));
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background: var(--effect-15-white, rgba(255, 255, 255, 0.15));
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    border: none;
    background: var(--Green, #0F0);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 8px;
    height: 13px;
    border: solid rgb(0, 0, 0);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.Width345px{
    max-width: 345px;
    min-width: 345px;
}


/******************* Input *******************/
._Valid {
    border: 2px solid var(--Green, #0F0);
}

._Invalid {
    border: 2px solid var(--Red, #F00);
}

._Active {
    border: 2px solid var(--Red, #F0F);
}

/***(❁´◡`❁)*** FONTS ***(❁´◡`❁)***/

.ABCRepro {
    font-family: 'ABCRepro', monospace;
}

/* 
h1 {
    color: #ffffff; 
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 74px;
}

h2 {
    color: #ffffff; 
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 58px; 
}

h3 {
    color: #ffffff; 
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

h4 {
    color: #ffffff; 
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    
}

h5 {
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
    word-wrap: break-word;
}

h6 {
    color: #ffffff; 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

p {
    color: #ffffff; 
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
u {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: underline;
}
i {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 18px;
} */
