/* Footer */
.Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.Footer .Navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0px;
    margin-bottom:-60px;

}
.Footer nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
}
.Footer nav a {
    color: white;
    font-weight: 700;
    text-decoration: none;
}
.Footer nav a:hover {
    color: var(--Yellow, #FF0);
}
.Footer nav a:hover {
    color: var(--White, #FFFF);
}

.Footer nav a.CurrentPage {
    color: var(--Yellow, #FF0); /* Adjust the color as per your theme */
    /* Or apply a yellow filter here if you prefer */
}


.Footer .AboutUs {
    color: #FFFF;
    
}
.Footer .PrivacyPolicy {
    color: #6E6E6E;
    
}
.Footer .AboutUs:hover {
    color: var(--Yellow, #FF0);
}
.Footer .PrivacyPolicy:hover {
    color: var(--Yellow, #FF0);
}

.Footer ._EarlyAccess {
    color: var(--Ice, #00FFF0);
}

.Footer ._EarlyAccess:hover {
    color: var(--Pink, #F0F);
}

.Footer ._Social {
    color: #A9A9A9;
    display: flex;
    gap: 8px;
}

.Footer ._SocialHover {
    color: #A9A9A9;
    display: flex;
    gap: 8px;
}

.Footer ._SocialHover:hover {
    filter: brightness(0%) saturate(100%) invert(94%) sepia(64%) saturate(5871%) hue-rotate(92deg) brightness(103%) contrast(107%);
}

.PoweredBy{
    margin-top: 50px;
    
}

@media screen and (max-width: 800px) {
    .Footer {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 140px;
    }
    
    .Footer nav {
        align-items: center;
        overflow-anchor: both;
    }

    .PoweredBy{
        margin-top: 173px;
    }
}
@media screen and (max-height: 740px) {
    .SantaHijack {

        margin-top: 100px;
    }
}
@media screen and (min-height: 741px) {
    .SantaHijack {

        margin-top: 180px;
    }
}