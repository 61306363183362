/*
  CSS grid is great for making video layouts.
  For the purposes of this demo, we're keeping things relatively simple.
  If you're interested in dynamic video grids, make sure to check out this blog post:
   https://www.daily.co/blog/add-pagination-to-a-custom-daily-video-chat-app-to-support-larger-meetings/
 */
.call {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .call {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
}

.is-screenshare {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 15px;
}

.self-view {
  display: none; /* Hide the self-view by default */
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .self-view {
    width: 240px;
    height: 135px;
  }
}

/* Hard-code the self-view size if there's no one else in the call, just to make it align with the info-box */
.self-view.alone {
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .self-view.alone {
    width: 240px;
    height: 135px;
  }
}

.self-view video {
  /* Mirror the self-view: it's weird not seeing yourself mirrored! */
  transform: rotate3d(0, 1, 0, 180deg);
}

/*
  When someone is sharing their screen, we want to resize the participants' videos,
  so the biggest screen in our grid is the screen share.
 */
.is-screenshare .self-view {
  width: 240px;
  height: 135px;
}

.info-box {
  background: var(--dark-blue-border);
  color: var(--white);
  width: 480px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .info-box {
    width: auto;
    height: auto;
  }
}

.room-url {
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
}

.loader {
  width: 48px;
  animation: spin 5s linear infinite;
}

/* animate to rotate */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loadingText {
  font-family: 'ABCRepro', monospace;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}
