/*^_^ Desktop responsiveness ^_^ */
@media ((min-width: 1101px)) {
.CookieConsent {
    bottom: 48px;
    right: 48px;
    width: 329px;
    max-width: 329px;
    }

  ._Width100{
    width: 22.37%;
    max-width: 22.37%;
  }
}
/*ಥ_ಥ Tablet responsiveness ಥ_ಥ*/
@media ((min-width: 469px) and (max-width: 1100px)) {
.CookieConsent {
    bottom: 48px;
    right: 48px;
    width: 329px;
    max-width: 329px;

  }
  ._Width100{
    width: 22.37%;
    max-width: 22.37%;
  }
}
/*O.O(❁´◡`❁) Mobile responsiveness (❁´◡`❁)O.O*/
@media (max-width: 469px) {
  .CookieConsent {
        margin: 17%;
        bottom: -48px;
        width: 100%;
        max-width: 329px;
    }

    ._Width100{
      width: 100%;
      max-width: 100%;
    }
}
@media (max-width: 361px) {
  .CookieConsent {
        margin: 17%;
        bottom: -48px;
        width: 100%;
        max-width: 300px;
    }

    ._Width100{
      width: 100%;
      max-width: 100%;
    }

    .Cookie{
      display:flex;
      width: 100px;
    }
    .CloseIcon{
      color:black;
      margin-left:-20px;
    }
}
@media (max-width: 330px) {
  .CookieConsent {
      align-items: center;
      left: 1%;
      right:1%;
      bottom: 5%;
      width: 220px;
      max-width: 280px;
      max-height:300px;
    }
    .CookieHeader {
      display:flex;
      align-items: row;
      gap:-88px;
    }
    ._Width100{
      width: 100%;
      max-width: 100%;
    }
}
@media (max-width: 300px) {
  .CookieConsent {
        align-items: center;
        left: 5%;
        right: 5%;
        bottom: 5%;
        width: 200px;
        max-width: 220px;
        height:700px;
    }

    .CookieContent {
      background: white;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      width: 150px;
    }

    ._Width100{
      width: 100%;
      max-width: 100%;
    }
    .Cookie{
      display:flex;
      width: 50px;
    }
    .CloseIcon{
      color:black;
      margin-left:-70px;
      align-self: flex-start;
    }
}
/* (●'◡'●) General CSS (●'◡'●) */
  .CookieConsent {
    position: fixed;
    display: flex;
    align-items:center;
    z-index: 1000;
    animation: slideIn 0.5s ease-in-out forwards;

  }
  .LinkButton {
    background: none;
    border: none;
    color: #00F;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
  .CookieContent {
    background: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .CookieButtons {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .DeclineButton {
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    color: blue;
    font-size: 14px;
    font-weight: 700;
  }
  
  .DeclineButton:hover {
    color: rgba(0, 0, 255, 0.5);

  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    0% {
      transform: translateY(0);
      scale: 1;
      opacity: 1;
    }
    50% {
      transform: translateY(-10%);
      scale: 0.95;
    }
    100% {
      transform: translateY(10%);
      scale: 0;
      opacity: 0;
    }
  }
  
.CookieHeader {
  display:flex;
  align-items: row;
  gap: 168px;
}
.Cookie {
  display:flex;
}
.CloseIcon{
  color:black;
  margin-top:-8px;
}
.ButtonClose{
  outline:none;
}

.ButtonClose:focus{
  color:rgba(0, 0, 0, 0.51);
}