* {
    box-sizing: border-box; /* This ensures padding doesn't add to the width */
}
.PrivacyPolicyScreen {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;

    background-color: black;
    color: #ffffff;

    padding: 60px;
    overflow-y: auto; /* In case the content is longer than the screen */

}

.PrivacyPolicyScreen-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px; /* Give some space below the header */

}
.PrivacyPolicyScreen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    width: 50%;
    margin-bottom: 30px; /* Give some space below the header */

}

.ButtonPrivacy {
    border-radius: 100px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    text-align: center;
    text-decoration: none;

    align-items: center;
    justify-content: center;
    column-gap: 8px;

    transition: 0.3s ease;
}

/* Button Hollow */

._HollowPrivacy {
    border: 2px solid var(--White, #FFF);
    background: var(--effect-15-white, rgba(255, 255, 255, 0));
}

._HollowPrivacy:hover {
    background: var(--effect-15-white, rgba(255, 255, 255, 0.15));
    border-radius: 0px;
}

._ButtonSizePrivacy {
    height: 52px;
    padding: 0px 64px 1px 64px;
}

@media screen and (max-width: 600px) {
    .PrivacyPolicyScreen {
        padding: 24px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .PrivacyPolicyScreen-content {
        width: 88%;
        margin-top: 80px;
        width: 345px;
        align-items: right;
        /* justify-content: center; */
    }

}
@media screen and (max-width: 395px) {

    .ButtonPrivacy{
        font-size: 13px;
        font-weight: 600;
    }
}
