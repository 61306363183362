.DemoScreen {
  background-color: black;
  min-height: 100vh;
  display: grid;
  color: white;
  /* this is where we're dividing our app in 3 rows: header, call, and tray. The meeting information
    is outside the app's document flow.*/
  /* grid-template-rows: 1fr 4rem; */
}

.DemoScreen .text_big {
  font-family: 'ABCRepro', monospace;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

.gray_link {
  font-family: 'ABCRepro', monospace;
  text-decoration: underline;
  text-align: center;
  color: #A9A9A9;
}

.gray_link:hover {
  text-decoration: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

/* Inverse of gray_link */
.white_link {
  font-family: 'ABCRepro', monospace;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.white_link:hover {
  text-decoration: underline;
  text-align: center;
  color: #A9A9A9;
  cursor: pointer;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.initial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100dvh;
  width: 100%;
  padding: 64px;
}

.initial-container .top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.DemoScreen .info {
  width: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.DemoScreen .info button {
  width: 100%;
}

.DemoScreen .info textarea {
  height: 161px;
}

.DemoScreen .info p {
  text-align: center;
}

.initial-container .info input {
  margin-bottom: 24px;
}

.initial-container .info button {
  margin-bottom: 32px;
}

/* .initial-container .info button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), #00F;
  } */

.initial-container .mic_info {
  width: 327px;
  height: 303px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.initial-container .mic_info button {
  width: 100%;
}

.initial-container .bottom {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 600px) {

  .DemoScreen .info {
    width: auto;
  }

  .initial-container {
    padding: 24px;
  }

  .initial-container .bottom {
    display: none;
  }
  
}

.api-error {
  background: var(--dark-blue-border);
  width: 480px;
  height: 270px;
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

.api-error p {
  color: var(--white);
}

.api-error h1 {
  color: var(--red-dark);
}

.api-error a,
.api-error a:visited {
  color: var(--white);
}

.micPermissionGranted {
  display: flex;
  filter: brightness(0%) saturate(100%) invert(57%) sepia(35%) saturate(5414%) hue-rotate(85deg) brightness(119%) contrast(124%);
}

.micPermissionGrantedButton {
  color: #000000;
  background-color: #00FF00;

}

.micPermissionFailure {
  background-color: #FF0000;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  height: 100dvh;
  width: 100%;
  padding: 64px;
}

@media screen and (max-width: 600px) {
  .feedback-container {
    justify-content: flex-end;
    padding: 24px;
  }
}

.feedback-container .info {
  gap: 32px;
}

.feedback-container .Link {
  width: 100%;
}