.ContactScreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;

    background-color: black;
    color: #ffffff;

    padding: 60px;
}

@media screen and (max-width: 600px) {
    .ContactScreen {
        padding: 24px;
    }
}

.Contact-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 40px;
    column-gap: 40px;

    margin-top: 80px;
    margin-bottom: 80px;
}

.Contact-item {
    width: 345px;
}

.Contact-info-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 40px;
    column-gap: 40px;
}

.Contact-form-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 16px;
    height: 545px;
}



.Contact-form-flex ._message {
    height: 161px;
    text-align: start;
}

.Contact-form-flex input {
    /* Body */
    font-family: 'ABCRepro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
}

.Contact-form-flex textarea {
    /* Body */
    font-family: 'ABCRepro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
}

.Contact-form-flex button {
    margin-top: 16px;
}

.Contact-form-flex button:hover {
    background-color: #00FF00;
    color: black;
}

@media screen and (max-width: 1300px) {
    .Contact-info-flex {
        flex-direction: column;
    }
}

@media screen and (max-width: 800px) {
    .Contact-info-flex {
        flex-direction: column;
    }

    .Contact-flex {
        margin-top: 160px;
        margin-bottom: 160px;
        justify-content: center;
    }
}



.ContactScreen h3 {
    color: var(--White, #FFF);


    font-family: "ABCReproMono";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.ContactScreen .text_gray {
    color: var(--Shades-of-grey-Medium, #A9A9A9);

    /* Body Mono */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
} 

.Contact-form-submitted {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #00FF00;
    font-size: 24px;
    text-align: center;
    margin-bottom: 0px;

    width: 259px;
    height: 475px;;
}

._contact-header {
    display: flex;
    gap: 8px;
}

._contact-header img {
    rotate: 90deg;
}

._mail {
    text-decoration: none;
    display: flex;
    gap: 8px;
    font-family: 'ABCRepro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
}

._mail:hover {
    cursor: pointer;
    filter: brightness(0%) saturate(100%) invert(88%) sepia(54%) saturate(2839%) hue-rotate(357deg) brightness(103%) contrast(103%);
}