.HelloButton {
    background-color: #FFFF00;
    color: black;
    width: 102px;
    height: 32px;
    border-radius: 50%;
    rotate: 30deg;
    border: none;
    font-size: 14px;
    margin-top: 13px;
}
.HelloButtonAutomatic {
    background-color: #FFFF00;
    color: black;
    width: 102px;
    height: 32px;
    border-radius: 50%;
    rotate: 30deg;
    border: none;
    font-size: 14px;
    margin-top: 13px;
}

@media screen and (max-width: 670px) {
    .HelloButton {
        transform: rotate(-15deg);
    }
}