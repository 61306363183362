

.SantaScreen {
    margin: 0;
    height: 100vh;
    padding: 0;
    background-color: black;
    box-sizing: border-box;
    overflow: hidden;
}

.SantaContainer {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-size: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.SantaContainer img {
    display: none;
}

.SantaContainer video {
    height: 125%;
    margin-bottom: -25vh;
    pointer-events: none;
    position:fixed;
}


video::-webkit-media-controls {
    display: none !important;
}

.Main {
    margin: 0;
    padding: 60px;
    height: 100dvh;
    background-size: cover;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    font-family: 'ABCReproMono', monospace;
}

.Top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

}

.Bottom {
    width: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-end;
    margin-bottom: 0px;
    z-index: 1;
}

header {
    display: flex;
    flex-wrap: wrap;
    row-gap: 48px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position:fixed;
    gap: 8px;
    z-index: 1;
    margin-bottom:0px;
    margin-top: 40vh;

}

@media screen and ((min-width: 765px) and (min-height: 500px)) {

    footer {
        margin-bottom:0px;
        margin-top: 70vh;
    
    }
}
@media screen and ((min-height: 900px)) {

    footer {
        margin-bottom:0px;
        margin-top: 80vh;    
    }
}






.SantaBubbleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -74px;
}

.SantaBubble {
    background-color: white;
    border-radius: 16px;
    width: 329px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.SantaBubble .Text {
    text-align: left;
    padding: 24px;
    font-size: 16px;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 4px;
    z-index: 500;
}

.SantaBubble .Text p {
    margin: 0px;
}

.Triangle {
    background-color: rgb(255, 255, 255);
    width: 35px;
    height: 35px;
    border-radius: 0% 0% 16% 0%;
    rotate: 45deg;
    margin-top: -25px;
}

.BookButton {
    border-radius: 100px;
    background: var(--effect-15-black, rgba(0, 0, 0, 0.15));
    /* BG Blur */
    backdrop-filter: blur(4px);
    border: none;
    padding: 0px 32px;
    height: 52px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    margin: 0px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    transition: 0.3s ease;
}

.TimeSlotsLeft {
    background: rgba(0, 0, 0, 0.4);
    /* Adjust the last value (0.7) for the desired opacity */
    border-radius: 5px;
    width: 178px;
    height: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    backdrop-filter: blur(5px);
    /* Adjust the value (5px) for the desired blur effect */
    /* Other styles for positioning, padding, etc. */
}

.TimeSlotsLeft p {
    margin: 0px;
}

.snowflake {
    position: absolute;
    background: #ffffff;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    pointer-events: none;
    animation: snowfall linear infinite;
    z-index: 100;
    filter: blur(2px);
    /* Adding a blur effect */
}

@keyframes snowfall {
    0% {
        transform: translateY(0) rotate(0deg);
    }

    100% {
        transform: translateY(90vh) rotate(360deg);
    }
}

.snowfall-container {
    position: absolute;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    z-index: -100;
}



@media screen and ((max-width: 450px) and (max-height: 699px)) {
    footer {
        width: 329px;
        position: fixed;
        bottom: 4%;
    }

    .SantaContainer video {
        display: block;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    
}


@media screen and (max-width: 670px) {
    .Top {
        position:fixed;
        padding: 20px;
        margin: 0px;
        margin-top: -20px;
    }

    .Main {
        padding: 24px;
    }

    .SantaBubbleContainer {
        order: 2;
        margin-top:10%;
    }

    header {
        width: 329px;
    }

    /* .SantaContainer img {
        display: block;
        width: 100%;
    } */

    .SantaContainer {
        margin-top:-16%;
    }
    .SantaContainer video {
        /* display: none; */
        height: 120vh;
        width: 100vh;
        max-width: 512px;
        overflow-y: hidden;
        overflow-x: hidden;
        padding-top: 40%;
    }

    .snowfall-container {
        display: none;
    }
    .Logo {
        margin-top:0px;
    }
}

@media screen and ((max-width: 670px) and (min-height: 670px)) {
    footer {
        width: 329px;
        position: fixed;
        bottom: 20%;
    }

    .SantaContainer {
        margin-top:-30%;
    }
}

